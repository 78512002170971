import React from "react";
import { Paper } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import Moment from 'moment';
import CarriersTable from "./CarriersTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAuthentication } from "../../Data/Authentication";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import cancelIconSVG from "../../Icons/CancelIcon.svg";
import verifyCarrierSVG from "../../Icons/shield-check.svg";
import plusIconSVG from "../../Icons/plusIcon.svg";
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";

function ViewCarriersList() {

    const { openLoader, closeLoader } = useLoader();
    const [carriersList, setCarriersList] = React.useState([]);
    const [carriersListFilter, setCarriersListFilter] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [carrierNumberFilter, setCarrierNumberFilter] = React.useState("");
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const [value, setValue] = React.useState(0);
    const [carrierStatusFilter, setCarrierStatusFilter] = React.useState(1);
    const carrierStatuses = ["Approved","Pending","Rejected"];

    const getData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.pageNumber = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.pageNumber = selectedPage;
        }
        if (carrierNumberFilter !== "")
            requestParameters.CarrierNumber = carrierNumberFilter;
        if (carrierNameFilter !== "")
            requestParameters.CarrierName = carrierNameFilter;
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        if (carrierStatusFilter !== "")
            requestParameters.StatusTypeId = [carrierStatusFilter];
        APIs.getCarriers(requestParameters).then((r) => (
            setCarriersList(r?.data),
            closeLoader(),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }

    React.useEffect(() => {
        getData();
    }, [selectedPage, pageSize, carrierNumberFilter, carrierNameFilter, sortFieldFilter, sortOrderFilter,carrierStatusFilter])

    const handleCarrierNumberFilter = (e) => {
        setCarrierNumberFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carrierNameFilter ?? "" })
        .then((r) => !(r?.data?.length) ? setCarriersListFilter([]) :
        setCarriersListFilter(r?.data));
        setCarrierNameAutoValue(carrierNameFilter);
    }, [carrierNameFilter])

    const handleClearSearch = () => {
        setCarrierNumberFilter("");
        setCarrierNameFilter("");
        setSelectedPage(1);
    }
    // handle tab change
    const handleTabChange = (e, newValue) => {
        setValue(newValue);
        setCarrierStatusFilter(newValue + 1);
        setSelectedPage(1);
        setPageSize(30);
    };

    return (
        <div className="mt-3">
            <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-400 ml-3">Carriers</h5>
            </div>
            <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                {/* iSolved Super Admin (2) || Super Admin(7) */}
                {(
                    userRoles?.includes('2') || 
                    userRoles?.includes('7')
            ) && (
                        <Button className="add-request blue-outlined-btn mr-2" href={`/Carriers/CreateCarrier`}>
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                            </svg> Carrier
                        </Button>
                    )}

                {userActions?.includes("20") && (
                    <Button hidden className="add-request blue-outlined-btn" href={`/Carriers/Search`}>
                        {/* <VerifiedUserIcon className="mr-2" /> */}
                        <svg width="20" height="24" viewBox="0 0 24 24" className="mr-2" >
                            <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                        </svg>
                        Verify Carrier
                    </Button>
                )}
            </div>
            <div>
                {/* tabs */}
                <MultipleTabsTitles tabTitle={carrierStatuses} value={value} setValue={setValue} onChange={handleTabChange} />
            </div>
            <Paper className="content-card-paper">
                <div className="search-container">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <div className="row">
                        <div className="col-xl-3 mt-3">
                            <span>Carrier Code:</span>
                            <br />
                            <input type="text" className="search-input w-100" value={carrierNumberFilter} onChange={handleCarrierNumberFilter} />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Carrier Name:</span>
                            <br />
                            <Autocomplete
                                options={carriersListFilter}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={carrierNameAutoValue ?? ""}
                                getOptionLabel={(option) => option?.CarrierName}
                                onChange={(e, value) => {
                                    setCarrierNameFilter(value?.CarrierName ?? "");
                                    setSelectedPage(1);
                                    setPageSize(30);
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option?.CarrierName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setCarrierNameAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-xl-12 mt-4" >
                            <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                                <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                    <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                </svg> Clear Search
                            </Button>
                        </div>
                    </div>
                    <CarriersTable
                        carriersList={carriersList}
                        setCarriersList={setCarriersList}
                        selectedPage={selectedPage}
                        pageSize={pageSize}
                        setSelectedPage={setSelectedPage}
                        setPageSize={setPageSize}
                        totalCount={totalCount}
                        onSortFieldFilter={(s) => setSortFieldFilter(s)}
                        onSortOrderFilter={(s) => setSortOrderFilter(s)}
                        carrierStatusFilter={carrierStatusFilter}

                    />
                </div>
            </Paper>
        </div>
    )
}

export default ViewCarriersList;