import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

function AnalystCreateFeedFormsWorkflow({
    onDFWorkflowTypeForm,
    // onHRSignatureForm,
    onHRFirstNameForm,
    onHRLastNameForm,
    // onEmployeeSignatureForm,
    ...props
}) {

    const [DFWorkflowType, setDFWorkflowType] = React.useState("");
    const DFsWorklowConfigurationTypes = [
        {
            Name: "Fully Automated",
            ID: "1"
        },
        {
            Name: "Approval Required",
            ID: "2",
        }, {
            Name: "Employee Signature Required",
            ID: "3"
        }
    ];
    // const [HRSignature, setHRSignature] = React.useState("");
    const [HRFirstName, setHRFirstName] = React.useState("");
    const [HRLastName, setHRLastName] = React.useState("");
    // const [employeeSignature, setEmployeeSignature] = React.useState("");

    const handleDFWorkflowTypeChange = (e) => {
        setDFWorkflowType(e.target.value);
        onDFWorkflowTypeForm(e.target.value);
    }

    return (
        <div>
            <FormControl component="fieldset" className="w-100">
                <RadioGroup aria-label="DFWorkflow" name="DFWorkflow" className="radio-list-options" value={DFWorkflowType ?? ""} onChange={handleDFWorkflowTypeChange}>
                    {DFsWorklowConfigurationTypes.map((d, di) => (
                        <React.Fragment key={`df-workflow-type-${di}`}>
                            <FormControlLabel value={d.ID} control={
                                <Radio disabled={d.Name === "Employee Signature Required"} checked={DFWorkflowType === d.ID} />
                            }
                                label={d.Name}
                            />
                            {DFWorkflowType === "2" && di === 1 && (
                                <div className="row mb-4">
                                    <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                        <h6 className="form-title">HR First Name</h6>
                                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                        <input required type="text" className="text-input w-100" value={HRFirstName ?? ""} onChange={e => { setHRFirstName(e.target.value); onHRFirstNameForm(e.target.value) }} />
                                    </div>
                                    <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                        <h6 className="form-title">HR Last Name</h6>
                                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                        <input required type="text" className="text-input w-100" value={HRLastName ?? ""} onChange={e => { setHRLastName(e.target.value); onHRLastNameForm(e.target.value) }} />
                                    </div>
                                    {/* <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                        <h6 className="form-title">HR Signature</h6>
                                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                        <input required type="text" className="text-input w-100" value={HRSignature ?? ""} onChange={e => { setHRSignature(e.target.value); onHRSignatureForm(e.target.value) }} />
                                    </div> */}
                                </div>
                            )}
                            {/* {DFWorkflowType === "Employee Signature Required" && di === 2 && (
                                <div className="row mb-4">
                                    <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                        <h6 className="form-title">Employee Signature</h6>
                                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                        <input required type="text" className="text-input w-100" value={employeeSignature ?? ""} onChange={e => { setEmployeeSignature(e.target.value); onEmployeeSignatureForm(e.target.value) }} />
                                    </div>
                                </div>
                            )} */}
                        </React.Fragment>
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default AnalystCreateFeedFormsWorkflow