import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserAssignementInternal from "./UserAssignmentInternal";

function UserAssignement({ selectedCarriers, ...props }) {

    const [expanded, setExpanded] = React.useState("UserAssignments");

    return (
        <Accordion className="carriers-contact-acc" expanded={expanded === `UserAssignments`} id="user-assignment">
            <AccordionSummary
                onClick={() => expanded === `UserAssignments` ? setExpanded("") : setExpanded("UserAssignments")}
                className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >
                <Typography className="title-typography">Connectivity Information</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <UserAssignementInternal selectedCarriers={selectedCarriers} />
            </AccordionDetails>
        </Accordion>
    )
}

export default UserAssignement;