import React, { useCallback, useEffect, useRef, useState } from "react";
import * as APIs from "../../../Data/APIs";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Button, Checkbox } from "@material-ui/core";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { useLoader } from "../../Layout/Loader";
import { debounce } from "lodash";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

function MapInternalCarrier({ iSolvedCarrier, forceCarrierListUpdate, ...props }) {
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader()
    const [internalCarriers, setInternalCarriers] = React.useState([]);
    const [selectedInternalCarrierObject, setSelectedInternalCarrierObject] = React.useState({
        CarrierInternalName: iSolvedCarrier?.CarrierInternalName,
        isOtherChecked: iSolvedCarrier?.isOtherChecked || false,
        CarrierID: iSolvedCarrier?.CarrierID
    });
    const [mappingSearchValue, setMappingSearchValue] = React.useState("");
    const [duplicateType, setDuplicateType] = useState("");
    const [duplicateCarriersList, setDuplicateCarriersList] = useState([]);
    const [similarCarriersList, setSimilarCarriersList] = useState([]);
    const [continueWithWarning, setContinueWithWarning] = useState(false);
    const [otherCarrierNameField, setOtherCarrierNameField] = useState(iSolvedCarrier?.CarrierName || iSolvedCarrier?.CarrierInternalName || "");
    const otherTextRef = useRef(null);
    const warningAndErrorsRef = useRef(null);
    const other = "other";

    useEffect(() => {
        if (!selectedInternalCarrierObject?.isOtherChecked) return;

        // Prevent re-renders by checking if values are already set
        const newCarrierName =
            duplicateType === "warning"
                ? continueWithWarning
                    ? otherCarrierNameField
                    : ""
                : duplicateType
                    ? ""
                    : otherCarrierNameField;

        if (selectedInternalCarrierObject.CarrierName !== newCarrierName) {
            setSelectedInternalCarrierObject(prev => ({
                ...prev,
                CarrierName: newCarrierName,
            }));
        }
    }, [otherCarrierNameField, duplicateType, continueWithWarning]);


    // the core function of carrier name validation
    const validateCarrierNameCore = async (carrierNameValue) => {
        openLoader();
        APIs.checkCarrierNameSameOrSimilar({ CarrierName: carrierNameValue })
            .then((res) => {
                setDuplicateType(res?.duplicateType?.toLowerCase() || '');
                setDuplicateCarriersList(res?.duplicateCarriers || []);
                setSimilarCarriersList(res?.similarCarriers || []);
            })
            .catch((error) => { console.log('Error while checking is same or similar carrier name :', error) })
            .finally(() => { closeLoader() })
    };

    const debouncedAsyncValidationForCarrierName = useCallback(
        debounce(validateCarrierNameCore, 1000),
        []
    );

    // on check other radio btn scroll to other text input
    useEffect(() => {
        if (selectedInternalCarrierObject?.isOtherChecked && otherTextRef.current) {
            otherTextRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [selectedInternalCarrierObject?.isOtherChecked, otherTextRef]);

    // scroll to Errors Or Warnings on change other text field
    useEffect(() => {
        setTimeout(() => {
            if (duplicateType && warningAndErrorsRef.current) {
                warningAndErrorsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 100);
    }, [duplicateType, warningAndErrorsRef, duplicateCarriersList.length, similarCarriersList.length]);

    React.useEffect(() => {
        APIs.getCarriers({StatusTypeId: [1,2]}).then(r => setInternalCarriers(r?.data)); //1,2 to get approved and pending carriers only
    }, [])

    const ResetOtherTextWarningErrors = () => {
        setOtherCarrierNameField("");
        setContinueWithWarning(false);
        setDuplicateCarriersList([]);
        setSimilarCarriersList([]);
        setDuplicateType("");
    }

    const handleSelectedInternalCarrierChange = (e) => {
        if (selectedInternalCarrierObject.isOtherChecked) {
            iSolvedCarrier.CarrierID = null;
            iSolvedCarrier.isOtherChecked = true;
            iSolvedCarrier.CarrierInternalName = selectedInternalCarrierObject?.CarrierName;
        } else {
            iSolvedCarrier.CarrierInternalName = selectedInternalCarrierObject?.CarrierName;
            iSolvedCarrier.CarrierID = parseInt(selectedInternalCarrierObject?.CarrierID);
            iSolvedCarrier.isOtherChecked = false;
        }
        if (forceCarrierListUpdate) forceCarrierListUpdate();
    };

    const handleMappingSearchValueChange = (e) => {
        setMappingSearchValue(e.target.value);
    }

    // To Set Default Mapped Carrier at the beginning 
    useEffect(() => {
        if (iSolvedCarrier.InternalCarrierID && internalCarriers.length > 0) {
            if (iSolvedCarrier.isOtherChecked) {
                setSelectedInternalCarrierObject({
                    CarrierInternalName: "",
                    isOtherChecked: true,
                    CarrierID: null,
                    CarrierName: iSolvedCarrier?.CarrierInternalName
                });
                setOtherCarrierNameField(iSolvedCarrier?.CarrierInternalName)
            } else {
                if (!iSolvedCarrier?.CarrierInternalName) {
                    setSelectedInternalCarrierObject(internalCarriers.find(ic => ic.CarrierID === iSolvedCarrier.InternalCarrierID));
                }
            }
        }
    }, [iSolvedCarrier, internalCarriers]);


    return (
        <div id="last-sent-file-modal">
            {internalCarriers?.length > 0 ?
                <FormControl component="fieldset">
                    <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={mappingSearchValue} onChange={handleMappingSearchValueChange} />
                    <RadioGroup aria-label="internalCarrier" name="internalCarrier" className="internalCarrier-radio-group" value={iSolvedCarrier?.CarrierID ?? ""}>
                        {/* filter for mapping search value */}
                        {internalCarriers.filter(c => mappingSearchValue ? c.CarrierName?.toLowerCase().includes(mappingSearchValue.toLowerCase()) : c).map((c, ci) => (
                            <FormControlLabel key={`internal-carrier-${ci}`} value={c?.CarrierID}
                                control={<Radio
                                    onClick={(e) => {
                                        setSelectedInternalCarrierObject(c);
                                        ResetOtherTextWarningErrors();
                                    }}
                                    checked={(selectedInternalCarrierObject?.CarrierID === c?.CarrierID)} />}
                                label={c?.CarrierName} />
                        ))}

                        {/* only show other option in case of searching on it or not searching at all*/}
                        {internalCarriers.length > 0 && other.slice(0, mappingSearchValue.length).toLowerCase() === mappingSearchValue.toLowerCase()
                            && (
                                <>
                                    <FormControlLabel value="Other"
                                        control={<Radio
                                            onClick={() => {
                                                setSelectedInternalCarrierObject({
                                                    CarrierInternalName: "",
                                                    isOtherChecked: true,
                                                    CarrierID: null
                                                })
                                                ResetOtherTextWarningErrors();
                                            }}
                                            checked={selectedInternalCarrierObject?.isOtherChecked}
                                        />} label="Other" />

                                    {selectedInternalCarrierObject?.isOtherChecked &&
                                        <>
                                            <div className="position-relative" ref={otherTextRef}>
                                                <h6 className="form-title">
                                                    Carrier Name <span className="text-danger mb-2 ml-2">*</span>
                                                </h6>
                                                <input
                                                    type={'text'}
                                                    value={otherCarrierNameField}
                                                    onChange={(event) => {
                                                        setOtherCarrierNameField(event.target.value);
                                                        debouncedAsyncValidationForCarrierName(event.target.value)
                                                    }}
                                                    className={`text-input w-100`}
                                                    placeholder={'Enter Carrier Name'}
                                                />
                                                <div className="warnings-and-errors" ref={warningAndErrorsRef}>
                                                    {duplicateType === "error" && <div className="text-danger" > This carrier name is duplicate as the following :
                                                        <div className="text-left fs-12  d-flex flex-column">
                                                            {duplicateCarriersList.map((dc, dci) =>
                                                                <a className='warningLink' key={`duplicatedCarrier-${dc?.CarrierID}`} href={`/Carriers/ViewCarrier/${dc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{dc?.CarrierName}</a>
                                                            )}
                                                        </div>
                                                    </div>}
                                                    {duplicateType === "warning" && !continueWithWarning && <div className="text-alert-yellow">This carrier name is similar to the following :
                                                        <div className="text-left fs-12 d-flex flex-column">
                                                            {similarCarriersList.map((sc, sci) =>
                                                                (<a className='warningLink' key={`similarCarrier-${sc?.CarrierID}`} href={`/Carriers/ViewCarrier/${sc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{sc?.CarrierName}</a>))}
                                                        </div></div>}
                                                    {duplicateType === "warning" && <>
                                                        <Checkbox
                                                            id={`Accept Similarity`}
                                                            value="AcceptSimilarity"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={continueWithWarning}
                                                            onClick={() => setContinueWithWarning(prev => !prev)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                        Accept Similarity
                                                    </>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                </>
                            )}
                    </RadioGroup>
                </FormControl> :
                <div className="fs-12" style={{ textTransform: "initial", fontWeight: "400", textAlign: "center" }}>
                    <br />
                    Please wait ......
                    <br />
                    <br />
                    <CircularProgress size="4rem" color="inherit" />
                </div>}
            <div className="row w-100 mt-5 custom-internal-mapping-actions" style={{ justifyContent: "center" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" disabled={!selectedInternalCarrierObject?.CarrierName} onClick={() => {
                    handleSelectedInternalCarrierChange();
                    dialog.closeDialog();
                }} >Map Selected</Button>
            </div>
        </div>
    )
}

export default MapInternalCarrier;