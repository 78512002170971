import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider, IconButton, FormControl } from "@material-ui/core";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from "@material-ui/core";
import * as APIs from "../../Data/APIs";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";

function FormRecord({
    form,
    index,
    uploadedForms,
    setUploadedForms,
    selectedCarriers,
    requestSubmitted,
    requestSaved,
    onSelectFormMainTypesValidationForm,
    onSelectFormSubTypesValidationForm,
    ...props
}) {

    const [, forceUpdate] = React.useReducer(x => !x, false);

    const [editIndex, setEditIndex] = React.useState(index);
    const [mainTypesList, setMainTypesList] = React.useState([]);
    const [subTypesList, setSubTypesList] = React.useState([]);

    const [mainTypesValidation, setMainTypesValidation] = React.useState("");
    const [subTypesValidation, setSubTypesValidation] = React.useState("");

    React.useEffect(() => {
        APIs.getDigitalFormsMainTypes().then(s => setMainTypesList(s?.data));
    }, [])

    React.useEffect(() => {
        APIs.getDigitalFormsSubTypes().then(s => setSubTypesList(s?.data));
    }, [])

    const handleDeleteForm = () => {
        uploadedForms.splice(editIndex, 1);
        uploadedForms = [...(uploadedForms ?? [])];
        setUploadedForms(uploadedForms);
    }

    React.useEffect(() => {
        if (!form.MainTypes || form.MainTypes?.length <= 0) {
            setMainTypesValidation("Missing form main type, please select at least one");
            onSelectFormMainTypesValidationForm("Missing form main type, please select at least one");
            forceUpdate();
        } else {
            setMainTypesValidation("");
            onSelectFormMainTypesValidationForm("");
        }
    }, [JSON.stringify(form), mainTypesValidation])

    React.useEffect(() => {
        if (!form.SubTypes || form.SubTypes?.length <= 0) {
            setSubTypesValidation("Missing form sub type, please select at least one");
            onSelectFormSubTypesValidationForm("Missing form sub type, please select at least one");
            forceUpdate();
        } else {
            setSubTypesValidation("");
            onSelectFormSubTypesValidationForm("");
        }
    }, [JSON.stringify(form), subTypesValidation])

    return (
        <div className="row container-div p-5 mt-2 alignvh-center" style={{ position: "relative" }}>
            <div style={{ position: "absolute", zIndex: "10", marginTop: "-95px" }}>
            <svg  width="21" height="24" viewBox="0 0 21 24" onClick={handleDeleteForm}  style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                {/* <DeleteForeverIcon onClick={handleDeleteForm} color="action" style={{ fontSize: "30px", cursor: "pointer" }} /> */}
            </div>
            <div className="container-internal-file-div col-xl-3">
                <PictureAsPdfIcon style={{ color: "var(--pink-primary-color)", fontSize: "50px" }} />
                <span style={{ wordBreak: "break-all", marginTop: "10px" }}>{form?.Name}</span>
            </div>
            <div className="col-xl-1"></div>
            <div id="DF-main-types" className="col-xl-8">
                <h6 className="form-title">Choose Main Types</h6>
                <FormControl label="Select Main Types" focused={false} variant="outlined" className="w-100">
                    <Select
                        className="text-dropdown"
                        value={form.MainTypes ?? []}
                        onChange={e => {
                            if (!form.MainTypes) form.MainTypes = [];
                            if (form.MainTypes.map(s => s.Name).indexOf(e.target.value[form.MainTypes?.length]) >= 0)
                                form.MainTypes.splice(form.MainTypes.indexOf({
                                    Name: e.target.value[form.MainTypes?.length],
                                    Id: mainTypesList.filter(s => (s.Name === e.target.value[form.MainTypes?.length]))[0]?.Id,
                                }), 1);
                            else {
                                form.MainTypes = [...form.MainTypes, {
                                    Name: e.target.value[form.MainTypes.length],
                                    Id: mainTypesList.filter(s => (s.Name === e.target.value[form.MainTypes.length]))[0]?.Id,
                                }];
                            }
                            forceUpdate();
                        }}
                        variant="outlined"
                        multiple
                        input={
                            <Input />
                        }
                        renderValue={(selected) => selected.map(s => s.Name).join(', ')}
                    >
                        {mainTypesList?.map((c, ci) => (
                            <MenuItem key={`main-types-${ci}`} value={c.Name}>
                                <Checkbox checked={form.MainTypes?.map(s => s.Name).indexOf(c.Name) > -1} />
                                <ListItemText primary={c.Name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{mainTypesValidation}</div>
                {form.MainTypes?.map((s, si) => (
                    <Chip
                        className="mt-1 mr-1"
                        key={`main-types-chips=${si}`}
                        label={s.Name}
                        onDelete={() => {
                            let array = form.MainTypes;
                            array.splice(si, 1);
                            form.MainTypes = [...array];
                            forceUpdate();
                        }}
                    />
                ))}

                <h6 className="form-title mt-5">Choose Sub Types</h6>
                <FormControl label="Select Sub Types" focused={false} variant="outlined" className="w-100">
                    <Select
                        className="text-dropdown"
                        value={form.SubTypes ?? []}
                        onChange={e => {
                            if (!form.SubTypes) form.SubTypes = [];
                            if (form.SubTypes.map(s => s.Name).indexOf(e.target.value[form.SubTypes?.length]) >= 0)
                                form.SubTypes.splice(form.SubTypes.indexOf({
                                    Name: e.target.value[form.SubTypes?.length],
                                    Id: subTypesList.filter(s => (s.Name === e.target.value[form.SubTypes?.length]))[0]?.Id,
                                }), 1);
                            else {
                                form.SubTypes = [...form.SubTypes, {
                                    Name: e.target.value[form.SubTypes.length],
                                    Id: subTypesList.filter(s => (s.Name === e.target.value[form.SubTypes.length]))[0]?.Id,
                                }];
                            }
                            forceUpdate();
                        }}
                        variant="outlined"
                        multiple
                        input={
                            <Input />
                        }
                        renderValue={(selected) => selected.map(s => s.Name).join(', ')}
                    >
                        {subTypesList?.map((c, ci) => (
                            <MenuItem key={`sub-types-${ci}`} value={c.Name}>
                                <Checkbox checked={form.SubTypes?.map(s => s.Name).indexOf(c.Name) > -1} />
                                <ListItemText primary={c.Name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{subTypesValidation}</div>
                {form.SubTypes?.map((s, si) => (
                    <Chip
                        className="mt-1 mr-1"
                        key={`sub-types-chips=${si}`}
                        label={s.Name}
                        onDelete={() => {
                            let array = form.SubTypes;
                            array.splice(si, 1);
                            form.SubTypes = [...array];
                            forceUpdate();
                        }}
                    />
                ))}
            </div>
        </div>

    )
}

export default FormRecord;